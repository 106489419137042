import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper
} from '@mui/material';
import useAuthenticatedData from '../../hooks/useAuthenticatedData';
import KpisGrid from './KpisGrid';
import MetricsGrid from './MetricsGridxx';
import GoalsGrid from '../overview/GoalsGrid';
import GroupBox from '../common/GroupBox';
import { useMsal } from "@azure/msal-react";


const GridWrapper = ({ title, children }) => (
  <Paper sx={{ color: 'white', p: 2, mb: 2 }}>
    <Typography variant="h6" gutterBottom>{title}</Typography>
    {children}
  </Paper>
);

const RepairDashboard = () => {
 

  const { instance, accounts } = useMsal();
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (accounts.length > 0) {
      const name = accounts[0].name || accounts[0].username || "User";
      setUsername(name);
    }
  }, [accounts]);

  return (
    <Paper elevation={3} sx={{ p: 1, maxWidth: '100%', mt: 4 }}>
      <Typography variant="h1" component="h1" gutterBottom align="left">
        Repair Dashboard
      </Typography>
      <Box sx={{ mt: 0, display: 'flex', gap: 2 }}>
        {/* Left side (A) */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ flex: 1, p: 0 }}>
            <GroupBox label="Actions">
              Place holder for actions
            </GroupBox>
          </Box>
          <Box sx={{ flex: 1, p: 0 }}>
            <GroupBox label="Repair Goals">
              Place holder for repair goals
            </GroupBox>
          </Box>
        </Box>

        {/* Right side (B) */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ flex: 1, p: 0 }}>
          <GroupBox label="Repair Opportunities">
               Place holder for repair opportunities
            </GroupBox>
          </Box>
          <Box sx={{ flex: 1,  p: 0 }}>
          <GroupBox label="Aging Repair Performance">
              Place holder for aging repair performance
            </GroupBox>
          </Box>
          <Box sx={{ flex: 1,   p:0 }}>
          <GroupBox label="Vendor Management">
              Place holder for vendor management
            </GroupBox>
          </Box>
        </Box>
      </Box>
    </Paper>
  );


};

export default RepairDashboard;