import React, { useMemo } from 'react';

import { Button, Paper, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { useQuery } from '@apollo/client';

import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar } from '@mui/x-data-grid';
import { GET_REPAIR_VENDORS } from '../../queries';
import { enterpriseGridOverrideTheme } from '../../themes/EnterpriseGridTheme';

const VendorOverview = () => {
    const navigate = useNavigate();

    const { data, loading, error } = useQuery(GET_REPAIR_VENDORS);


    const rows = useMemo(() => {
        if (!data?.repairVendors || !Array.isArray(data.repairVendors)) return [];
        return data.repairVendors.map((item, index) => ({
            id: index,
            acctNo: item.acctNo, // Include acctno in the row data
            name: item.name,
            sotd: item.sotd,
            repairCost: item.repairCost,
            serviceLevel: item.serviceLevel,
            berScrap: item.berScrap,
            warranty: item.warranty,
            overallScore: item.overallScore,
        }));
    }, [data]);


    // Row click handler
    const handleRowClick = (params) => {
        console.log(params);
        const acctNo = params.row.acctNo;
        navigate(`/repair-vendors/vendor?acctno=${acctNo}`);
    };

    // generateExportFileName - <ccode>-<date>-quotes-for-day
    const generateExportFileName = () => {
        return 'vendor-list';
    };

    const columns = useMemo(
        () => [

            {
                field: 'name',
                headerName: 'Name',
                width: 300
            },
     
            {
                field: 'sotd',
                headerName: 'SOTD',
              //  width: 250
            },
            {
                field: 'repairCost',
                headerName: 'Repair Cost',
           //     width: 250
            }, 
            {
                field: 'serviceLevel',
                headerName: 'Service Level',
             //   width: 250
            }, 
            {
                field: 'berScrap',
                headerName: 'BER/Scrap',
            //    width: 250
            },
            {
                field: 'warranty',
                headerName: 'Warranty',
           //     width: 250
            },
            {
                field: 'overallScore',
                headerName: 'Overall Score',
           //     width: 250
            },
        ],
        []
    );
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    return (
        <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
            <Typography variant="h1" component="h1" gutterBottom align="left">
                Repair Vendors
            </Typography>
            <Box sx={{ height: '80vh', width: '100%' }}> {/* Set a fixed height here */}
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Typography>Loading...</Typography>
                    </Box>
                ) : error ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Typography color="error">Error: {error.message}</Typography>
                    </Box>
                ) : (
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        disableSelectionOnClick
                        autoHeight={false}
                        // Set explicit row and header heights
                        //rowHeight={32}
                        headerHeight={40}
                        sx={enterpriseGridOverrideTheme}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true, // Enable quick filter
                                quickFilterProps: { debounceMs: 500 }, // Add debounce to avoid too many filter operations
                                csvOptions: { fileName: generateExportFileName() },
                                printOptions: { fileName: generateExportFileName() }
                            }
                        }}
                        onRowClick={handleRowClick} // Add the row click handler

                    />
                )}
            </Box>
        </Paper>
    );
};

export default VendorOverview;
