import React, { useState, useEffect, useMemo } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
} from '@mui/material';
import GroupBox from '../../common/GroupBox';
import { useQuery } from '@apollo/client';
import { GET_CLOSED_REPAIRS_FOR_VENDOR, GET_OPEN_REPAIRS_FOR_VENDOR } from '../../../queries';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar } from '@mui/x-data-grid';
import { enterpriseGridOverrideTheme } from '../../../themes/EnterpriseGridTheme';
import VendorScatterChart from './RepairVendorScatterChart';

const RepairVendorOverallTab = ({ acctNo }) => {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 3,
                p: 0,
                width: '100%',
                height: '25vh', // Set a height for demonstration purposes
            }}
        >
            {/* Left */}
            <Box
                sx={{
                    flex: '1 1 50%',

                }}
            >
                <GroupBox label="SOTD & Repair Costs">
                    <VendorScatterChart acctNo={acctNo} />

                </GroupBox>
            </Box>

            {/* Right */}
            <Box
                sx={{
                    flex: '1 1 50%',
                    display: 'flex',
                    flexDirection: 'column',

                }}
            >
                {/* Stack these */}
                <Box sx={{ flex: 1, }}>
                    <GroupBox label="Service Level">
                        <Typography variant="body1">Service: 4/5</Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            Last Reviewed 10/2/2024 (2 months ago) by Daniel D.
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            Gist: Usually on time, worked through several BER issues
                        </Typography>
                    </GroupBox>
                </Box>
                <Box sx={{ flex: 1, }}>
                    <GroupBox label="BER">
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>1M</TableCell>
                                    <TableCell>3M</TableCell>
                                    <TableCell>6M</TableCell>
                                    <TableCell>12M</TableCell>
                                    <TableCell>24M</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Line Count</TableCell>
                                    <TableCell>2</TableCell>
                                    <TableCell>3</TableCell>
                                    <TableCell>4</TableCell>
                                    <TableCell>6</TableCell>
                                    <TableCell>8</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>RA| Fees</TableCell>
                                    <TableCell>$4,000</TableCell>
                                    <TableCell>$6,000</TableCell>
                                    <TableCell>$8,000</TableCell>
                                    <TableCell>$10,000</TableCell>
                                    <TableCell>$12,000</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Opportunity Cost</TableCell>
                                    <TableCell>$40,000</TableCell>
                                    <TableCell>$60,000</TableCell>
                                    <TableCell>$80,000</TableCell>
                                    <TableCell>$100,000</TableCell>
                                    <TableCell>$120,000</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </GroupBox>
                </Box>
                <Box sx={{ flex: 1, }}>
                    <GroupBox label="Warranty">
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>1M</TableCell>
                                    <TableCell>3M</TableCell>
                                    <TableCell>6M</TableCell>
                                    <TableCell>12M</TableCell>
                                    <TableCell>24M</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Line Count</TableCell>
                                    <TableCell>2</TableCell>
                                    <TableCell>2</TableCell>
                                    <TableCell>3</TableCell>
                                    <TableCell>4</TableCell>
                                    <TableCell>5</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Est Price</TableCell>
                                    <TableCell>$4,000</TableCell>
                                    <TableCell>$6,000</TableCell>
                                    <TableCell>$8,000</TableCell>
                                    <TableCell>$10,000</TableCell>
                                    <TableCell>$12,000</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Avg Resolution</TableCell>
                                    <TableCell>20 days</TableCell>
                                    <TableCell>18 days</TableCell>
                                    <TableCell>21 days</TableCell>
                                    <TableCell>34 days</TableCell>
                                    <TableCell>31 days</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </GroupBox>
                </Box>
            </Box>
        </Box>

    )

}


export default RepairVendorOverallTab;