// SalespersonSelect.js
import React, { useState, useEffect } from 'react';
import { TextField, CircularProgress, Autocomplete, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';

import { GET_REPAIR_VENDOR_LIST } from '../../../queries';

const VendorSelect = ({ onVendorSelect, defaultVendorAcctNo }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [options, setOptions] = useState([]);

  // Fetch all salespeople
  const { data: allData, loading: allLoading, error: allError } = useQuery(GET_REPAIR_VENDOR_LIST);

 
  // Handle errors
  useEffect(() => {
    if (allError) console.error('Error fetching all salespeople:', allError);
  }, [allError]);

  // Set options once all salespeople are fetched
  useEffect(() => {
    if (allData?.repairVendorList) {
      setOptions(allData.repairVendorList);
    }
  }, [allData]);

 
  const handleInputChange = (event, value) => {
    setInputValue(value);
    // No need for debounce since all data is pre-fetched
  };

  const handleVendorSelect = (event, value) => {
    setSelectedVendor(value);
    if (value) {
      setInputValue(value.name);
      onVendorSelect(value);
    } else {
      setInputValue('');
      onVendorSelect(null);
    }
  };

 

  return (
    <>
      {(allError) && (
        <Typography variant="body2" color="error">
          {allError?.message || 'An error occurred while fetching salespeople.'}
        </Typography>
      )}
      <Autocomplete
        sx={{ minWidth: 400, mt: 0 }}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={allLoading}
        value={selectedVendor}
        onChange={handleVendorSelect}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        isOptionEqualToValue={(option, value) => option.acctNo === value.acctNo}
        noOptionsText="No vendors found"
        renderOption={(props, option) => (
          <li {...props} key={option.acctNo}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {option.name}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ marginLeft: 1 }}>
              {option.acctNo}
            </Typography>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            sx={{ mt: 0 }}
            {...params}
            label="Select Vendor"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {(allLoading) ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default VendorSelect;
