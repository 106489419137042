import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, Box, Typography, Button } from '@mui/material';

const ModulesHome = () => {
    const columns = [
        { field: 'featureName', headerName: 'Feature Name', width: 200 },
        {
            field: 'videoLink',
            headerName: 'Video',
            width: 300,
            align: 'center', // Align the cell content
            headerAlign: 'center', // Align the header
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: '#1976d2', color: '#fff' }}
                        href={params.value}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Watch  
                    </Button>
                </Box>
            ),
        },
        {
            field: 'documentLink',
            headerName: 'Document',
            width: 300,
            align: 'center', // Align the cell content
            headerAlign: 'center', // Align the header
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: '#1976d2', color: '#fff' }}
                        href={params.value}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        View  
                    </Button>
                </Box>
            ),
        },
    ];
    
    const rows = [
        {
            id: 1,
            featureName: 'Salesperson Scorecard',
            videoLink: 'http://example.com/salesperson-video',
            documentLink: 'http://example.com/salesperson-doc',
        },
        {
            id: 2,
            featureName: 'Customer Scorecard',
            videoLink: 'http://example.com/customer-video',
            documentLink: 'http://example.com/customer-doc',
        },
    ];

    return (
        <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
            <Typography variant="h1" component="h1" gutterBottom align="left">
                Training Modules
            </Typography>

            <Box sx={{ height: 400, mt: 0 }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                />
            </Box>
        </Paper>
    );
};

export default ModulesHome;