// src/config/msalConfig.js

import { getAuthScopes } from './config/scopes'; // Adjust the path based on your project structure

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    }
};

export const loginRequest = {
    scopes: getAuthScopes()
};

export const loginSilentRequest = {
    scopes: getAuthScopes(),
    account: null
};


/*
export const msalConfig = {
    auth: {
        clientId: "82ba1170-03da-46d4-be4b-dcc3fb4473f1",
        authority: "https://login.microsoftonline.com/33b1ca7f-20ff-4f96-8e27-74acdd92e4bf",
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    }
};

export const loginRequest = {
 //  scopes: ["openid", "profile", "User.Read"]
    scopes: ["api://82ba1170-03da-46d4-be4b-dcc3fb4473f1/access_as_user"]
};


export const loginSilentRequest = {
    scopes: ["api://82ba1170-03da-46d4-be4b-dcc3fb4473f1/access_as_user"],
    account: null, // Will be set dynamically
  };
  */


