import React from 'react';
import { Box } from '@mui/material';

const GroupBox = ({ 
  label, 
  children,
  sx,
  ...props 
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        border: '1px solid',
        borderColor: 'white',
        borderRadius: 1,
        padding: 1,
        margin: 1,
        ...sx
      }}
      {...props}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '-0.75em',
          left: '1em',
          padding: '0 0.5em',
          backgroundColor: 'background.paper',
          fontSize: '0.75rem',
          color: 'text.secondary'
        }}
      >
        {label}
      </Box>
      <Box sx={{ '& > *:not(:first-child)': { marginTop: 1 } }}>
        {children}
      </Box>
    </Box>
  );
};

export default GroupBox;