import React, { useState, useEffect, useMemo } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
} from '@mui/material';
import GroupBox from '../../common/GroupBox';
import { useQuery } from '@apollo/client';
import { GET_CLOSED_REPAIRS_FOR_VENDOR, GET_OPEN_REPAIRS_FOR_VENDOR } from '../../../queries';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar } from '@mui/x-data-grid';
import { enterpriseGridOverrideTheme } from '../../../themes/EnterpriseGridTheme';

const RepairVendorCurrentStatusTab = ({ acctNo }) => {

    const { data: openRepairs, loading: loadingOpenRepairs, error: errorOpenRepairs } = useQuery(GET_OPEN_REPAIRS_FOR_VENDOR, {
        variables: { acctNo: acctNo },
    });


    const openRepairRows = useMemo(() => {
        if (!openRepairs?.openRepairsForVendor) return [];
        return openRepairs.openRepairsForVendor.map((item, index) => ({
            id: index,
            ...item,
        }));
    }, [openRepairs]);


    const { data: closedRepairs, loading: loadingClosedRepairs, error: errorClosedRepairs } = useQuery(GET_CLOSED_REPAIRS_FOR_VENDOR, {
        variables: { acctNo: acctNo, startDate: '2021-01-01', endDate: '2021-12-31' },
    });


    const closedRepairRows = useMemo(() => {
        if (!closedRepairs?.recentClosedRepairsForVendor) return [];
        return closedRepairs.recentClosedRepairsForVendor.map((item, index) => ({
            id: index,
            ...item,
        }));
    }, [closedRepairs]);



    const openRepairsColumns = useMemo(
        () => [
            { field: 'pentagonDocNo', headerName: 'Pentagon Doc#', width: 120 },
            { field: 'partNumber', headerName: 'Partnumber', width: 80 },
            { field: 'openAgeInDays', headerName: 'Age', width: 120 },
            //  { field: 'status', headerName: 'Status', width: 120 },
            { field: 'forStockYN', headerName: 'For Stock', width: 120 },
            { field: 'salesOrderYN', headerName: 'Sales Order', width: 120 },
            { field: 'value', headerName: 'Value', width: 120 },
        ],
        []
    );



    const closedRepairsColumns = useMemo(
        () => [
            { field: 'pentagonDocNo', headerName: 'Pentagon Doc#', width: 120 },
            { field: 'partNumber', headerName: 'Partnumber', width: 80 },
            { field: 'closedOutcome', headerName: 'Outcome', width: 120 },
        ],
        []
    );

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0,
            p: 0,
            width: '100%'
        }}>
            <Box sx={{
                display: 'flex',
                gap: 0,
                width: '100%'
            }}>


                {/* Top Box - Open Repairs */}
                <Box sx={{ width: '40%' }}>

                    <GroupBox label="Open Repairs">

                        <DataGrid
                            rows={openRepairRows}
                            columns={openRepairsColumns}
                            pageSize={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            disableSelectionOnClick={false}
                            density='compact'

                            autoHeight={true}
                            headerHeight={40}
                            sx={{
                                ...enterpriseGridOverrideTheme,
                                '& .MuiDataGrid-cell': {
                                    padding: '0px', 
                                    backgroundColor: 'white',
                                    color: 'black',
                                  },
                                '& .MuiDataGrid-row': {
                                    cursor: 'pointer',
                                    '&.Mui-selected': {
                                        backgroundColor: 'primary.main',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                        },
                                    },
                                },
                            }}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },

                                }
                            }}
                        />



                    </GroupBox>
                </Box>



                <Box sx={{ width: '30%' }}>
                    <GroupBox label="Recent Closed Repairs">
                        <DataGrid
                            rows={closedRepairRows}
                            columns={closedRepairsColumns}
                            pageSize={5}
                            density='compact'
                            rowsPerPageOptions={[5, 10, 25]}
                            disableSelectionOnClick={false}
                        
                            autoHeight={true}
                            headerHeight={40}
                            sx={{
                                ...enterpriseGridOverrideTheme,
                                '& .MuiDataGrid-cell': {
                                    padding: '0px', 
                                    backgroundColor: 'white',
                                    color: 'black',
                                  },
                                '& .MuiDataGrid-row': {
                                    cursor: 'pointer',
                                    '&.Mui-selected': {
                                        backgroundColor: 'primary.main',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                        },
                                    },
                                },
                            }}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },

                                }
                            }}
                        />
                    </GroupBox>
                </Box>

                <Box sx={{ width: '30%' }}>
                    <GroupBox label="Review Status">
                        <Box sx={{ p: 2 }}>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: 'bold',
                                    mb: 2
                                }}
                            >
                                Status: On-Track
                            </Typography>

                            <Box sx={{ pl: 2 }}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontSize: '1.1rem',
                                        mb: 1
                                    }}
                                >
                                    Last Reviewed: 10/2/2024
                                </Typography>

                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontSize: '1.1rem'
                                    }}
                                >
                                    Next Review: 10/2/2025
                                </Typography>
                            </Box>
                        </Box>
                    </GroupBox>
                </Box>
            </Box>

        </Box>




    );
};

export default RepairVendorCurrentStatusTab;