// src/hooks/useAuthenticatedDownload.js

import { useState, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { getAuthScopes } from '../config/scopes'; // Adjust the path based on your project structure
import { InteractionRequiredAuthError } from '@azure/msal-browser';

/**
 * Custom hook to handle authenticated file downloads.
 * Utilizes dynamic scopes based on environment configurations.
 *
 * @returns {Object} - An object containing the downloadFile function, loading state, and error.
 */
const useAuthenticatedDownload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { instance, accounts } = useMsal();

  /**
   * Downloads a file from the specified URL with authentication.
   *
   * @param {string} url - The API endpoint to download the file from.
   * @param {string} filename - The desired name for the downloaded file.
   */
  const downloadFile = useCallback(async (url, filename) => {
    if (!accounts.length) {
      setError(new Error('No account available'));
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Retrieve scopes dynamically from environment variables
      const scopes = getAuthScopes();

      if (scopes.length === 0) {
        throw new Error('No authentication scopes defined in REACT_APP_AUTH_SCOPES.');
      }

      // Acquire token silently using dynamic scopes
      const accessTokenResponse = await instance.acquireTokenSilent({
        scopes: scopes, // Use dynamic scopes
        account: accounts[0],
      });

      // Make the authenticated API request to download the file
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessTokenResponse.accessToken}` },
        responseType: 'blob', // This is important for file downloads
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a link element, set the download attribute with the filename
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      // Append to the document, click and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Free up memory
      window.URL.revokeObjectURL(link.href);
    } catch (err) {
      console.error('Error downloading file:', err);
      setError(err);

      if (err instanceof InteractionRequiredAuthError) {
        // Retrieve scopes dynamically before initiating interactive authentication
        const scopes = getAuthScopes();

        if (scopes.length === 0) {
          console.error('No authentication scopes defined in REACT_APP_AUTH_SCOPES.');
          return;
        }

        // Initiate interactive token acquisition
        instance.acquireTokenRedirect({
          scopes: scopes, // Use dynamic scopes
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [instance, accounts]);

  return { downloadFile, isLoading, error };
};

export default useAuthenticatedDownload;


/*
import { useState, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';

const useAuthenticatedDownload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { instance, accounts } = useMsal();

  const downloadFile = useCallback(async (url, filename) => {
    if (!accounts.length) {
      setError(new Error("No account available"));
      return;
    }
   
    setIsLoading(true);
    setError(null);

    try {
      await instance.initialize();

      const accessToken = await instance.acquireTokenSilent({
        scopes: ['api://82ba1170-03da-46d4-be4b-dcc3fb4473f1/access_as_user'],
        account: accounts[0],
      });

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken.accessToken}` },
        responseType: 'blob', // This is important for file downloads
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      
      // Create a link element, set the download attribute with the filename
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      
      // Append to the document, click and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Free up memory
      window.URL.revokeObjectURL(link.href);

    } catch (error) {
      console.error('Error downloading file:', error);
      setError(error);
      if (error.name === "InteractionRequiredAuthError") {
        instance.acquireTokenRedirect({
          scopes: ['api://82ba1170-03da-46d4-be4b-dcc3fb4473f1/access_as_user'],
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [instance, accounts]);

  return { downloadFile, isLoading, error };
};

export default useAuthenticatedDownload;
*/