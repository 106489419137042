import React, { useState, useMemo } from 'react';
import {
    Box,
    Tabs,
    Tab,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import HorizontalCalendarScroller from '../../CalendarMasterDetail/HorizontalCalendarScroller';
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import { GET_REPAIR_VENDOR_SCORE_OVER_TIME, GET_REPAIR_VENDOR_LIST } from '../../../queries'; // Adjust the path as needed
import { useQuery } from '@apollo/client';
import VendorSelect from './VendorSelect';
import { enterpriseGridOverrideTheme } from '../../../themes/EnterpriseGridTheme';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar } from '@mui/x-data-grid';
import GroupBox from '../../common/GroupBox';
import ScrollableMetricsTable from './ScrollableMetricsTable';
import RepairVendorOverallTab from './RepairVendorOverallTab';
import RepairVendorCurrentStatusTab from './RepairVendorCurrentStatusTab';
import RepairVendorSOTDTab from './RepairVendorSOTDTab';
 

const ScrollableCalendarContainer = styled(Box)({
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    borderBottom: '1px solid #e0e0e0'
});

const DetailSection = styled(Box)({
    display: 'flex',
    height: 'calc(100vh - 120px)',
    '& > div': {
        flex: 1,
        overflow: 'auto'
    }
});

const StyledTableCell = styled(TableCell)({
    padding: '4px 8px'
});

const tabs = ['Current Status', 'Overall', 'SOTD', 'Repair Cost', 'Service Level', 'BER/Scrap', 'Warranty'];

const RepairVendor = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedVendor, setSelectedVendor] = useState(null);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const endDate = endOfMonth(currentDate);
    const startDate = startOfMonth(addMonths(currentDate, -12));

    // generateExportFileName - <ccode>-<date>-quotes-for-day
    const generateExportFileName = () => {
        return 'vendor';
    };

    const activitiesConfig = useMemo(() => [
        {
            type: 'QUOTES',
            color: '#1976d2',
            dataFields: {
                docCount: 'quoteDocCount',
                lineCount: 'quoteLineCount',
                total: 'quoteTotal',
            },
            minimal: false,
            weeklyAverage: true,
            monthlyAverage: true,
        },
        {
            type: 'SALES',
            color: '#2D875D',
            dataFields: {
                docCount: 'salesOrderDocCount',
                lineCount: 'salesOrderLineCount',
                total: 'salesOrderTotal',
            },
            minimal: false,
            weeklyAverage: true,
            monthlyAverage: true,
        },
        {
            type: 'CALLS',
            color: '#9C4EE5',
            dataFields: {
                docCount: 'callDocCount',
                lineCount: 'callCount',
                total: 'callTotal',
            },
            minimal: true,
            weeklyAverage: false,
            monthlyAverage: false,
        },
    ], []);

    // Fetch repair vendor score data using GraphQL
    const { data: productionData, loading, error } = useQuery(
        GET_REPAIR_VENDOR_SCORE_OVER_TIME,
        {
            variables: { acctNo: selectedVendor ? selectedVendor.acctNo : '', startDate: startDate.toISOString(), endDate: endDate.toISOString() },
            skip: !selectedVendor, // Skip query if no vendor is selected
        }
    );

    const formattedProductionData = useMemo(() => {
        if (!productionData) return [];
        const result = productionData.scoresForVendorOverTime.map((item) => ({
            date: item.date,
            ...item,
        }));
        console.log('Formatted Production Data:', result);
        return result;
    }, [productionData]);

    const handleVendorSelect = (vendor) => {
        setSelectedVendor(vendor);
    };

    const TabContent = {
        'Current Status': () => <RepairVendorCurrentStatusTab acctNo={selectedVendor ? selectedVendor.acctNo : ''} />,
        'Overall': () => <RepairVendorOverallTab acctNo={selectedVendor ? selectedVendor.acctNo : ''} />,  
        'SOTD': () =>  <RepairVendorSOTDTab acctNo={selectedVendor ? selectedVendor.acctNo : ''} />,
        'Repair Cost': () => <div>Repair Cost Component</div>,
        'Service Level': () => <div>Service Level Component</div>,
        'BER/Scrap': () => <div>BER/Scrap Component</div>,
        'Warranty': () => <div>Warranty Component</div>
    };
    const columns = useMemo(
        () => [
            { field: 'docNo', headerName: 'Pentagon Doc#', width: 120 },
            { field: 'userLine', headerName: 'Line', width: 80 },
            { field: 'acctno', headerName: 'Account', width: 120 },
            { field: 'partnumber', headerName: 'Part Number', width: 150 },
            { field: 'description', headerName: 'Description', width: 150 },
            { field: 'condition', headerName: 'Condition', width: 100 },
            { field: 'serialNumber', headerName: 'Serial Number', width: 130 },
            {
                field: 'qtyReq',
                headerName: 'Qty Req',
                width: 80,
                type: 'number',
            },
            {
                field: 'qtyBid',
                headerName: 'Qty Bid',
                width: 80,
                type: 'number',
            },
            {
                field: 'lineTotal',
                headerName: 'Line Total',
                width: 130,
                valueFormatter: (params) => {
                    console.log(params);
                    if (params.value == null) return '';
                    return `$${Number(params.value).toLocaleString()}`;
                },
                type: 'number',
            },
            { field: 'ata', headerName: 'ATA', width: 80 },
            { field: 'traceTo', headerName: 'Trace To', width: 80 },
            { field: 'tagInfo', headerName: 'Tag', width: 80 },
            { field: 'customerContact', headerName: 'Contact', width: 120 },
        ],
        []
    );

    return (
        <Paper elevation={3} sx={{ maxWidth: '100%', mt: 4, p:0 }}>
            <Box sx={{ color: 'white' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #e0e0e0',
                        padding: '0px', // Added padding for better spacing
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Orbitron, sans-serif',
                            color: '#FDB813',
                            fontStyle: 'italic',
                            fontSize: '1.5rem',
                        }}
                    >
                        {`${selectedVendor ? selectedVendor.name : 'Select a vendor'} - ${tabs[selectedTab]}`}
                    </Typography>

                    <Typography variant="body2" sx={{ color: 'gray' }}>
                        {/* Additional information can be displayed here if needed */}
                    </Typography>
                    <VendorSelect onVendorSelect={handleVendorSelect} />
                </Box>

                <GroupBox label="Score History">
                    {selectedVendor ? (
                        <ScrollableMetricsTable data={formattedProductionData} />
                    ) : (
                        <Typography variant="body1" sx={{ padding: '0px', color: 'gray' }}>
                            Please select a vendor to view score history.
                        </Typography>
                    )}
                </GroupBox>

                <DetailSection>
                    <Box>
                        <Tabs
                            value={selectedTab}
                            onChange={(_, newValue) => setSelectedTab(newValue)}
                            variant="scrollable"
                            scrollButtons="auto"
                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#FDB813'
                                }
                            }}
                            disabled={!selectedVendor} // Disable tabs if no vendor is selected
                        >
                            {tabs.map((tab, index) => (
                                <Tab
                                    key={tab}
                                    label={tab}
                                    sx={{
                                        minHeight: 36,
                                        color: selectedTab === index ? '#ffffff !important' : '#b3b3b3',
                                        fontWeight: selectedTab === index ? 'bold' : 'normal'
                                    }}
                                />
                            ))}
                        </Tabs>
                        <Box sx={{ mt: 1, p: 0, color: 'white' }}>
                            {selectedVendor ? (
                                TabContent[tabs[selectedTab]]()
                            ) : (
                                <Typography variant="body1" sx={{ color: 'gray' }}>
                                    Please select a vendor to view details.
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </DetailSection>
            </Box>
        </Paper>
    );
};

export default RepairVendor;
