// components/SalespersonTabSet/SalespersonTabSet.jsx

import React, { useState, useEffect, useMemo } from 'react';
import { Box, Tabs, Tab, Typography, Select, MenuItem, FormControl } from '@mui/material';

import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import './SalespersonTabSet.module.css';
import SalespersonSelect from './SalespersonSelect';
import SalespersonProfile from './SalespersonProfile';
import CalendarMasterDetail from '../../CalendarMasterDetail/CalendarMasterDetail'; // Adjust the path as needed
import '@fontsource/orbitron/700.css'; // For bold weight

import { Circle, CircleOutlined } from '@mui/icons-material';

// Import your GraphQL query
import { GET_DAILY_SALESPERSON_SALES_PRODUCTION, GET_DAILY_SALESPERSON_PRICING_PRODUCTION, GET_ASSIGNED_ACCOUNTS } from '../../../queries'; // Adjust the path as needed
import SalespersonPerformance from './SalespersonPerformance';
import SalespersonCustomerSelect from './SalespersonCustomerSelect';

import SalespersonQuoteDetail from './SalespersonQuoteDetail.js';
import SalespersonCallDetail from './SalespersonCallDetail.js';
import SalespersonSalesDetail from './SalespersonSalesDetail.js';
import SalespersonRepairsRFQsDetail from './SalespersonRepairsRFQsDetail.js';
import SalespersonPurchaseRFQsDetail from './SalespersonPurchaseRFQsDetail.js';
import SalespersonPEDetail from './SalespersonPEDetail.js';
import SalespersonProficiency from './SalespersonProficiency';



const SalespersonTabSet = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeCalendarTab, setActiveCalendarTab] = useState(0);
  const [selectedSalesperson, setSelectedSalesperson] = useState(null);

  const [searchParams] = useSearchParams();
  const defaultSalespersonCode = searchParams.get('code'); // Ensure 'code' is used
  const defaultTab = searchParams.get('tab');
  const [selectedCustomer, setSelectedCustomer] = useState('');


  const { data: assignedAccountsData, loading: loadingAccounts, error: errorAccounts } = useQuery(GET_ASSIGNED_ACCOUNTS, {
    variables: { ccode: selectedSalesperson ? selectedSalesperson.cCode : '' },
    skip: !selectedSalesperson, // Skip if no salesperson selected
  });

  // Fetch production data using GraphQL
  const { data: productionData, loading, error } = useQuery(
    GET_DAILY_SALESPERSON_SALES_PRODUCTION,
    {
      variables: { ccode: selectedSalesperson ? selectedSalesperson.cCode : '' },
      skip: !selectedSalesperson, // Skip the query if no salesperson is selected
    }
  );

  const { data: productionPricingData, loadingPricing, errorPricing } = useQuery(
    GET_DAILY_SALESPERSON_PRICING_PRODUCTION,
    {
      variables: { ccode: selectedSalesperson ? selectedSalesperson.cCode : '' },
      skip: !selectedSalesperson, // Skip the query if no salesperson is selected
    }
  );

  // Set the initial active tab based on the 'tab' parameter in the URL
  useEffect(() => {
    if (defaultTab) {
      const tabIndex = parseInt(defaultTab, 10); // Convert the 'tab' parameter to an integer
      if (!isNaN(tabIndex) && tabIndex >= 0 && tabIndex <= 4) {
        setActiveTab(tabIndex);
      }
    }
  }, [defaultTab]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCustomerChange = (event) => {
    setSelectedCustomer(event.target.value);
  };


  const handleCalendarTabChange = (event, newValue) => {
    setActiveCalendarTab(newValue);
  };

  const handleSalespersonSelect = (salesperson) => {
    console.log('Salesperson selected in TabSet:', salesperson); // Debugging
    setSelectedSalesperson(salesperson);
  };

  // Define activities configuration for Production
  const activitiesConfig = useMemo(() => [
    {
      type: 'QUOTES',
      color: '#1976d2',
      
      dataFields: {
        docCount: 'quoteDocCount',
        lineCount: 'quoteLineCount',
        total: 'quoteTotal',
      },
      minimal: false,
      weeklyAverage: true,
      monthlyAverage: true,

      detailView: SalespersonQuoteDetail,


    },
    {
      type: 'SALES',
      color: '#2D875D',
      dataFields: {
        docCount: 'salesOrderDocCount',
        lineCount: 'salesOrderLineCount',
        total: 'salesOrderTotal',
      },
      minimal: false,
      weeklyAverage: true,
      monthlyAverage: true,
      detailView: SalespersonSalesDetail,

    },
    {
      type: 'CALLS',
      color: '#9C4EE5',
      dataFields: {
        docCount: 'callDocCount',
        lineCount: 'callCount',
        total: 'callTotal',
      },
      minimal: true,
      weeklyAverage: false,
      monthlyAverage: false,
      detailView: SalespersonCallDetail,

    },
  ], []);

 // rgb(47 89 214) ? #2f59d6
  // Define activities configuration for Production
  const pricingActivitiesConfig = useMemo(() => [
    {
      type: 'RFQ-PURCHASES',
      color: '#3B5998',
      fontColor: 'black', 

      dataFields: {
        docCount: 'purchaseRFQDocCount',
        lineCount: 'purchaseRFQLineCount',
        total: '',
      },
      minimal: true,
      weeklyAverage: false,
      monthlyAverage: false,
      detailView: SalespersonPurchaseRFQsDetail,
    },

    {
      type: 'RFQ-REPAIRS',
      color: '#B87333',
      fontColor: 'black', 

      dataFields: {
        docCount: 'repairRFQDocCount',
        lineCount: 'repairRFQLineCount',
        total: '',
      },
      minimal: true,
      weeklyAverage: false,
      monthlyAverage: false,
      detailView: SalespersonRepairsRFQsDetail,
    },
    {
      type: 'PRICING',
      color: '#A23E48',
      fontColor: 'black', 

      dataFields: {
        docCount: '',
        lineCount: 'pricingEvents',
        total: '',
      },
      minimal: true,
      weeklyAverage: false,
      monthlyAverage: false,
      detailView: SalespersonPEDetail,
    },

  ], []);

  const assignedAccounts = useMemo(() => {
    if (assignedAccountsData?.assignedAccounts) {
      return assignedAccountsData.assignedAccounts.map(item => item.name);
    }
    return [];
  }, [assignedAccountsData]);

  // Format the production data as needed for CalendarMasterDetail
  const formattedProductionData = useMemo(() => {
    if (!productionData) return [];
    return productionData.dailySalespersonSalesProduction.map((item) => ({
      date: item.date,
      ...item, // Spread all fields dynamically
    }));
  }, [productionData]);

  // Format the production data as needed for CalendarMasterDetail
  const formattedProductionPricingData = useMemo(() => {
    if (!productionPricingData) return [];
    console.log('pricing data: ', productionPricingData);
    return productionPricingData.dailySalespersonPricingProduction.map((item) => ({
      date: item.date,
      ...item, // Spread all fields dynamically
    }));
  }, [productionPricingData]);

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* Salesperson Title and Select */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '16px',
          paddingLeft: '16px',
          borderBottom: '1px solid #e0e0e0',
        }}
      >
        <Typography sx={{
          fontFamily: 'Orbitron, sans-serif',

          color: '#FDB813',
          fontStyle: 'italic',
          fontSize: '1.5rem',
        }}  >
          {selectedSalesperson
            ? `${selectedSalesperson.fullName}`
            : 'No Salesperson Selected'}
        </Typography>
        <Typography variant="body2" sx={{ color: 'gray' }}>
  
        </Typography>
       
        <SalespersonSelect
          onSalespersonSelect={handleSalespersonSelect}
          defaultSalespersonCode={defaultSalespersonCode}
        />
           
      </Box>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', borderBottom: 1, borderColor: 'divider'
      }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="salesperson tabs"
          TabIndicatorProps={{
            style: { backgroundColor: 'white' }, // Maintains the indicator's white color for the active tab
          }}
        >
          <Tab
            label="Profile"
            sx={{
              color: activeTab === 0 ? '#ffffff !important' : '#b3b3b3',
              fontWeight: activeTab === 0 ? 'bold' : 'normal',
            }}
          />
          <Tab
            label="Quotes, Sales, & Calls"
            sx={{
              color: activeTab === 1 ? '#ffffff !important' : '#b3b3b3',
              fontWeight: activeTab === 1 ? 'bold' : 'normal',
            }}
          />
          <Tab
            label="RFQs & Pricing"
            sx={{
              color: activeTab === 2 ? '#ffffff !important' : '#b3b3b3',
              fontWeight: activeTab === 2 ? 'bold' : 'normal',
            }}
          />
          <Tab
            label="Performance"
            sx={{
              color: activeTab === 3 ? '#ffffff !important' : '#b3b3b3',
              fontWeight: activeTab === 3 ? 'bold' : 'normal',
            }}
          />
          <Tab
            label="Proficiency"
            sx={{
              color: activeTab === 4 ? '#ffffff !important' : '#b3b3b3',
              fontWeight: activeTab === 4 ? 'bold' : 'normal',
            }}
          />
        </Tabs>

        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>

          {activeTab === 0 && (
            <Box sx={{
              color: '#ceaf07',

            }}>

               
            </Box>
          )}
          {activeTab === 1 && (
            <>
              <Box sx={{
                color: '#ceaf07',

              }}>

                 
              </Box>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                ml: 1
              }}>
                <Circle sx={{ fontSize: 12, color: 'white' }} />
                <CircleOutlined sx={{ fontSize: 12, color: 'white' }} />
              </Box>


            </>
          )}
          {activeTab === 2 && (
            <>
              <Box sx={{
                color: '#ceaf07',

              }}>

                 
              </Box>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                ml: 1
              }}>
                <CircleOutlined sx={{ fontSize: 12, color: 'white' }} />
                <Circle sx={{ fontSize: 12, color: 'white' }} />

              </Box>


            </>
          )}
          {activeTab === 3 && (
            <Box sx={{
              color: '#ceaf07',

            }}>

               
            </Box>
          )}
          {activeTab === 4 && (
            <Box sx={{
              color: '#ceaf07',

            }}>

               
            </Box>
          )}


        </Box>

   {/*
        <SalespersonCustomerSelect userAssignedCustomers={assignedAccounts} />
        */}

      </Box>


      <Box id="tab-box" sx={{ flexGrow: 1, overflow: 'hidden', p: 0, height: '100%' }}>
        {activeTab === 0 && (
          <SalespersonProfile selectedSalesperson={selectedSalesperson} />
        )}
        {activeTab === 1 && (
          selectedSalesperson ? (
            loading ? (
              <Typography variant="body1" sx={{ p: 2 }}>
                Loading Production Data...
              </Typography>
            ) : error ? (
              <Typography variant="body1" sx={{ p: 2, color: 'red' }}>
                Error loading production data.
              </Typography>
            ) : (
              <div>


                <CalendarMasterDetail
                  //    activityClicked={ActivityClicked}
                  businessKey={selectedSalesperson.cCode}
                  data={formattedProductionData}
                  currentYear={new Date().getFullYear()} // Or derive from data
                  currentMonth={new Date().getMonth()} // Or derive from data
                  onMonthChange={(year, month) => {
                    // Handle month change if needed
                    console.log(`Month changed to: ${month + 1}/${year}`);
                  }}
                  activities={activitiesConfig}

                />



              </div>
            )
          ) : (
            <Typography variant="body1" sx={{ p: 2 }}>
              Please select a salesperson to view production data.
            </Typography>
          )
        )}
        {activeTab === 2 && (

          selectedSalesperson ? (
            loadingPricing ? (
              <Typography variant="body1" sx={{ p: 2 }}>
                Loading Production Data...
              </Typography>
            ) : errorPricing ? (
              <Typography variant="body1" sx={{ p: 2, color: 'red' }}>
                Error loading production data.
              </Typography>
            ) : (
              <div>


                <CalendarMasterDetail
                  //    activityClicked={ActivityClicked}
                  businessKey={selectedSalesperson.cCode}
                  data={formattedProductionPricingData}
                  currentYear={new Date().getFullYear()} // Or derive from data
                  currentMonth={new Date().getMonth()} // Or derive from data
                  onMonthChange={(year, month) => {
                    // Handle month change if needed
                    console.log(`Month changed to: ${month + 1}/${year}`);
                  }}
                  activities={pricingActivitiesConfig}

                />



              </div>
            )
          ) : (
            <Typography variant="body1" sx={{ p: 2 }}>
              Please select a salesperson to view production data.
            </Typography>
          )
        )}
        {activeTab === 3 && (
          /* Replace with your actual Performance component */
          <SalespersonPerformance selectedSalesperson={selectedSalesperson} />

        )}
        {activeTab === 4 && (
          /* Replace with your actual Proficiency component */
          <SalespersonProficiency selectedSalesperson={selectedSalesperson} />
        )}
      </Box>
    </Box>
  );
};

export default SalespersonTabSet;
