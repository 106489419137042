import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Paper, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { enterpriseGridOverrideTheme } from '../../themes/EnterpriseGridTheme';
 
const SalesOverview = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    const handleButtonClick = () => {
        navigate('/sales/salesperson'); // Navigate to the desired route
    };

    return (
        <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
            <Typography variant="h1" component="h1" gutterBottom align="left">
                Sales
            </Typography>
            <Box sx={{ mt: 2 }}>
                <Button
                  //  sx={{ backgroundColor: '#4f62ed' }}
                    variant="contained"
                    color="info"

                    onClick={handleButtonClick} // Add onClick handler
                >
                    Go To Salesperson View
                </Button>
            </Box>
        </Paper>
    );
};

export default SalesOverview;
