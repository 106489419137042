// SalespersonSelect.js
import React, { useMemo } from 'react';
import { Typography, Paper, Box } from '@mui/material';
import { useQuery } from '@apollo/client';
import { DataGrid , GridToolbarContainer, GridToolbarExport, GridToolbar } from '@mui/x-data-grid';
import { GET_SALES_FOR_DAY_FOR_SALESPERSON } from '../../../queries';
import { enterpriseGridOverrideTheme } from '../../../themes/EnterpriseGridTheme';

 

const SalespersonSalesDetail = ({ businessKey, date }) => {
  const { data, loading, error } = useQuery(GET_SALES_FOR_DAY_FOR_SALESPERSON, {
    variables: { ccode: businessKey, date: date },
  });

  const rows = useMemo(() => {
    if (!data?.salesOrdersForDayForSalesperson) return [];
    return data.salesOrdersForDayForSalesperson.map((item, index) => ({
      id: index, // Ensure unique IDs; consider using a unique identifier from data if available
      ...item,
    }));
  }, [data]);

  // generateExportFileName - <ccode>-<date>-quotes-for-day
  const generateExportFileName = () => {
    const formattedDate = date.toISOString().split('T')[0]; // Gets YYYY-MM-DD
    const dateParts = formattedDate.split('-');
    return `${businessKey}-${dateParts[1]}-${dateParts[2]}-${dateParts[0]}-sales-orders-for-day`;
  };


  const columns = useMemo(
    () => [
      { field: 'docNo', headerName: 'Pentagon Doc#', width: 120 },
      { field: 'userLine', headerName: 'Line', width: 80 },
 
      { field: 'acctno', headerName: 'Account', width: 120 },

      { field: 'accountName', headerName: 'Name', width: 120 },

      { field: 'partnumber', headerName: 'Part Number', width: 150 },

      { field: 'description', headerName: 'Description', width: 150 },

      { field: 'condition', headerName: 'Condition', width: 100 },
      { field: 'serialNumber', headerName: 'Serial Number', width: 130 },
      {
        field: 'shippedDate',
        headerName: 'Shipped Date',
        width: 120,
        type: 'date',
        valueFormatter: (params) => {
          if (!params) return '';
          return new Date(params).toLocaleDateString();
        }
      },
      {
        field: 'estShipDate',
        headerName: 'Est Ship Date',
        width: 120,
        type: 'date',
        valueFormatter: (params) => {
          if (!params) return '';
          return new Date(params).toLocaleDateString();
        }
      },
      {
        field: 'qtyOrdered',
        headerName: 'Qty',
        width: 80,
        type: 'number',
      },
 
      {
        field: 'lineTotal',
        headerName: 'Line Total',
        width: 130,
        valueFormatter: (params) => {
          console.log(params);

          if (params == null) return '';
          return `$${params.toLocaleString()}`;
        },
        type: 'number',
      },
      { field: 'ata', headerName: 'ATA', width: 80 },
      // traceTo
      // tagInfo
      { field: 'traceTo', headerName: 'Trace To', width: 80 },
      { field: 'tagInfo', headerName: 'Tag', width: 80 },
 
    ],
    []
  );

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div>
      <Typography sx={{ p: '8px' }} variant="h6">
        Sales Orders for Day
      </Typography>

      <Paper elevation={3} sx={{ height: 400, width: '100%', p: 2 }}>
        <Box sx={{ height: '100%' }}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <Typography>Loading...</Typography>
            </Box>
          ) : error ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <Typography color="error">Error: {error.message}</Typography>
            </Box>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 25]}
              disableSelectionOnClick
              autoHeight={false}
              // Set explicit row and header heights
              //rowHeight={32}
              headerHeight={40}
              sx={enterpriseGridOverrideTheme}
              slots={{ toolbar: GridToolbar }}
              slotProps={{ toolbar: { 
                showQuickFilter: true, // Enable quick filter
                quickFilterProps: { debounceMs: 500 }, // Add debounce to avoid too many filter operations
                csvOptions: { fileName: generateExportFileName() }, 
                printOptions: { fileName: generateExportFileName() }
            } }}
            />
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default SalespersonSalesDetail;
