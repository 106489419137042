import React, { useEffect } from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_SALESPERSON_QUOTE_TO_CLOSE } from '../../../queries';
import { GET_SALESPERSON_SALES_VS_SELL_PRICE } from '../../../queries';
import { GET_SALES_BY_AGE_CATEGORY_FOR_SALESPERSON } from '../../../queries';
import { GET_SALES_BY_ATA_FOR_SALESPERSON } from '../../../queries';
import './SalespersonProfile.module.css';

const InfoItem = ({ label, value }) => (
    <Box className="info-item">
        <Typography className="info-label">{label}:</Typography>
        <Typography className="info-value">{value}</Typography>
    </Box>
);

const Section = ({ title, children }) => (
    <Paper className="section">
        <Typography className="section-title">{title}</Typography>
        <Box className="section-content">
            {children}
        </Box>
    </Paper>
);

const formatCurrency = (amount) => {
    if (amount === null || amount === undefined) return 'N/A';
    if (amount === 0) return '$0';
    const absAmount = Math.abs(Math.floor(amount)).toLocaleString();
    return amount < 0 ? `-$${absAmount}` : `$${absAmount}`;
};

const formatPercent = (value) => {
    if (value === null || value === undefined) return 'N/A';
    if (value === 0) return '0.0%';
    const absValue = Math.abs(value).toFixed(1); 
    return value < 0 ? `-${absValue}%` : `${absValue}%`;
};

const SalespersonProficiency = ({ selectedSalesperson }) => {
    const { data: quoteToCloseData, loading: quoteLoading, error: quoteError } = useQuery(GET_SALESPERSON_QUOTE_TO_CLOSE, {
        variables: { ccode: selectedSalesperson?.cCode || '' },
        skip: !selectedSalesperson,
    });

    const { data: salesVsSellData, loading: salesLoading, error: salesError } = useQuery(GET_SALESPERSON_SALES_VS_SELL_PRICE, {
        variables: { ccode: selectedSalesperson?.cCode || '' },
        skip: !selectedSalesperson,
    });

    const { data: salesByAgeData, loading: salesByAgeLoading, error: salesByAgeError } = useQuery(GET_SALES_BY_AGE_CATEGORY_FOR_SALESPERSON, {
        variables: { ccode: selectedSalesperson?.cCode || '' },
        skip: !selectedSalesperson,
    });

    const { data: salesByAtaData, loading: salesByAtaLoading, error: salesByAtaError } = useQuery(GET_SALES_BY_ATA_FOR_SALESPERSON, {
        variables: { ccode: selectedSalesperson?.cCode || '' },
        skip: !selectedSalesperson,
    });

    useEffect(() => {
        if (quoteError) console.error('Error fetching quote to close:', quoteError);
        if (salesError) console.error('Error fetching sales vs sell price:', salesError);
        if (salesByAgeError) console.error('Error fetching sales by age:', salesByAgeError);
        if (salesByAtaError) console.error('Error fetching sales by ATA:', salesByAtaError);
    }, [quoteError, salesError, salesByAgeError, salesByAtaError]);

    if (quoteLoading || salesLoading || salesByAgeLoading || salesByAtaLoading) return <Typography sx={{ p: 3 }}>Loading...</Typography>;
    if (quoteError) return <Typography sx={{ p: 3 }}>Error: {quoteError.message}</Typography>;
    if (salesError) return <Typography sx={{ p: 3 }}>Error: {salesError.message}</Typography>;
    if (salesByAgeError) return <Typography sx={{ p: 3 }}>Error: {salesByAgeError.message}</Typography>;
    if (salesByAtaError) return <Typography sx={{ p: 3 }}>Error: {salesByAtaError.message}</Typography>;
    if (!quoteToCloseData || !salesVsSellData) return <Typography sx={{ p: 3 }}>No data available</Typography>;

    const quoteToClose = quoteToCloseData.salespersonQuoteToCloseByCcode;
    const salesVsSellPice = salesVsSellData.salesVsSellPriceForSalesperson;
    const salesByAge = salesByAgeData.salesByAgeCategoryForSalesperson;
    const salesByAta = salesByAtaData.salesByATAForSalesperson;

    return (
        <Box className="customer-profile">
            <Grid container spacing={2} className="grid-container">
                {/* Top row */}
                <Grid item xs={12} md={6} className="grid-item">
                    {/* Q2C Section */}
                    <Section title="Quote to Close">
                        <Grid container spacing={2} className="nested-grid-container">
                            <Grid  sx={{height:'200px'}} item xs={12} sm={12} className="nested-grid-item">
                            <Box sx={{ overflowX: 'auto', width: '100%' }}>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '8px' }}>
                                        <thead  >
                                            <tr>
                                                <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left'}}></th>
                                                <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>30 Days</th>
                                                <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>6 Months</th>
                                                <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>12 Months</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', fontWeight: 500 }}>Percent Quantity</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>{formatPercent(quoteToClose.percentQ2CQty1m ? quoteToClose.percentQ2CQty1m : 0)} </td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>{formatPercent(quoteToClose.percentQ2CQty6m ? quoteToClose.percentQ2CQty6m : 0)} </td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>{formatPercent(quoteToClose.percentQ2CQty12m ? quoteToClose.percentQ2CQty12m : 0)} </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', fontWeight: 500 }}>Percent Dollars</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>{formatPercent(quoteToClose.percentQ2CDollars1m ? quoteToClose.percentQ2CDollars1m : 0)} </td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>{formatPercent(quoteToClose.percentQ2CDollars6m ? quoteToClose.percentQ2CDollars6m : 0)} </td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>{formatPercent(quoteToClose.percentQ2CDollars12m ? quoteToClose.percentQ2CDollars12m : 0)} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Box>

                            </Grid>
                          
                        </Grid>
                    </Section>
                </Grid>

                <Grid item xs={12} md={6} className="grid-item">
                    {/* Sales Vs Sell Price Section */}
                    <Section title="Sales vs. Sell Price">
                        <Grid container spacing={2} className="nested-grid-container">
                            <Grid item xs={12} className="nested-grid-item">
                            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '8px' }}>
                                        <thead  >
                                            <tr>
                                                <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left'}}></th>
                                                <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>30 Days</th>
                                                <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>6 Months</th>
                                                <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>12 Months</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', fontWeight: 500 }}>Dollars</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>{formatCurrency(salesVsSellPice.salesVsSellPriceDollars1m ? salesVsSellPice.salesVsSellPriceDollars1m : 0)} </td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>{formatCurrency(salesVsSellPice.salesVsSellPriceDollars6m ? salesVsSellPice.salesVsSellPriceDollars6m : 0)} </td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>{formatCurrency(salesVsSellPice.salesVsSellPriceDollars12m ? salesVsSellPice.salesVsSellPriceDollars12m : 0)} </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', fontWeight: 500 }}>Percent</td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>{formatPercent(salesVsSellPice.salesVsSellPricePercent1m ? salesVsSellPice.salesVsSellPricePercent1m : 0)} </td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>{formatPercent(salesVsSellPice.salesVsSellPricePercent6m ? salesVsSellPice.salesVsSellPricePercent6m : 0)} </td>
                                                <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>{formatPercent(salesVsSellPice.salesVsSellPricePercent12m ? salesVsSellPice.salesVsSellPricePercent12m : 0)} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                            </Grid>
                        </Grid>
                    </Section>
                </Grid>

                {/*  
                <Grid item xs={12} md={6} className="grid-item">
         
                    <Section title="Sales by Age">
                        <Grid  sx={{height:'200px'}} container spacing={2} className="nested-grid-container">
                            <Grid item xs={12} className="nested-grid-item">
      

                            </Grid>
                        </Grid>
                    </Section>
                </Grid>

                <Grid item xs={12} md={6} className="grid-item">
    
                    <Section title="Sales by ATA">
                       


                        <Grid container spacing={2} className="nested-grid-container">
                            <Grid item xs={12} className="nested-grid-item">
                    

                            </Grid>
                        </Grid>


                    </Section>
                </Grid>
                */}
            </Grid>
        </Box>
    );
};

export default SalespersonProficiency;
