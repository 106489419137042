// src/hooks/useAuthenticatedData.js

import { useState, useEffect, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { getAuthScopes } from '../config/scopes'; // Adjust the path based on your project structure

/**
 * Custom hook to fetch authenticated data from a given URL.
 * Utilizes dynamic scopes based on environment configurations.
 *
 * @param {string} url - The API endpoint to fetch data from.
 * @returns {Object} - An object containing the fetched data, loading state, error, and a refetch function.
 */
const useAuthenticatedData = (url) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { instance, accounts } = useMsal();

  const fetchData = useCallback(async () => {
    if (!accounts.length) {
      setIsLoading(false);
      setError(new Error("No account available"));
      return;
    }
   
    setIsLoading(true);
    setError(null);

    try {
      // Retrieve scopes dynamically from environment variables
      const scopes = getAuthScopes();
      
      if (scopes.length === 0) {
        throw new Error('No authentication scopes defined in REACT_APP_AUTH_SCOPES.');
      }

      // Acquire token silently using dynamic scopes
      const accessTokenResponse = await instance.acquireTokenSilent({
        scopes: scopes, // Use dynamic scopes
        account: accounts[0],
      });

      // Make the authenticated API request
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessTokenResponse.accessToken}` },
      });

      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
      if (error.name === "InteractionRequiredAuthError") {
        // Retrieve scopes dynamically before initiating interactive authentication
        const scopes = getAuthScopes();
        
        if (scopes.length === 0) {
          console.error('No authentication scopes defined in REACT_APP_AUTH_SCOPES.');
          return;
        }

        // Initiate interactive token acquisition
        instance.acquireTokenRedirect({
          scopes: scopes, // Use dynamic scopes
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [url, instance, accounts]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refetch = useCallback(() => {
    fetchData();
  }, [fetchData]);

  return { data, isLoading, error, refetch };
};

export default useAuthenticatedData;


/*
import { useState, useEffect, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';

const useAuthenticatedData = (url) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { instance, accounts } = useMsal();

  const fetchData = useCallback(async () => {
    if (!accounts.length) {
      setIsLoading(false);
      setError(new Error("No account available"));
      return;
    }
   
    setIsLoading(true);
    setError(null);

    try {
      await instance.initialize();

      const accessToken = await instance.acquireTokenSilent({
        scopes: ['api://82ba1170-03da-46d4-be4b-dcc3fb4473f1/access_as_user'],
        account: accounts[0],
      });

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken.accessToken}` },
      });

      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
      if (error.name === "InteractionRequiredAuthError") {
        instance.acquireTokenRedirect({
          scopes: ['api://82ba1170-03da-46d4-be4b-dcc3fb4473f1/access_as_user'],
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [url, instance, accounts]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refetch = useCallback(() => {
    fetchData();
  }, [fetchData]);

  return { data, isLoading, error, refetch };
};

export default useAuthenticatedData;
*/