import React, { useEffect, useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from '@mui/material';

const ScrollableMetricsTable = ({ data }) => {
  console.log('Received data:', data);
  const tableContainerRef = useRef(null); // Reference to the TableContainer

  const scaleFactor = 1;



  useEffect(() => {
    if (tableContainerRef.current) {
      const scrollWidth = tableContainerRef.current.scrollWidth;
      const clientWidth = tableContainerRef.current.clientWidth;
      tableContainerRef.current.scrollLeft = scrollWidth - clientWidth; // Scroll to the rightmost position
    }
  }, [data]); // Run when data changes

  const metrics = ['SOTD', 'Repair Cost', 'Service Level', 'BER', 'Warranty', 'Overall'];

  const formatMonth = (date) => {
    const d = new Date(date);
    const month = d.toLocaleDateString('en-US', { month: 'short' }).toUpperCase();
    const year = d.toLocaleDateString('en-US', { year: '2-digit' });
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body2">{month} {year}</Typography>

      </Box>
    );
  };

  // Define background colors for each metric row
  const metricColors = {
    SOTD: '#006400',          // Dark Green
    'Repair Cost': '#00008B', // Dark Blue
    'Service Level': '#4B0082', // Indigo
    BER: '#8B0000',           // Dark Red
    Warranty: '#FF8C00',      // Dark Orange
    Overall: '#004c4c',       // Darker Teal for better contrast
  };

  // Define color scales for metric values (1-5)
  const metricValueColors = {
    SOTD: ['#004d00', '#006400', '#008000', '#00b300', '#00e600'],          // Dark to bright green
    'Repair Cost': ['#00004d', '#00008B', '#0000b3', '#0000e6', '#3333ff'], // Dark to bright blue
    'Service Level': ['#2e0854', '#4B0082', '#6a0dad', '#8a2be2', '#9370db'], // Dark to bright indigo
    BER: ['#4d0000', '#8B0000', '#b30000', '#e60000', '#ff3333'],           // Dark to bright red
    Warranty: ['#cc6600', '#ff6600', '#ff8000', '#ff9933', '#ffb366'],       // Dark to bright orange
    Overall: ['#004c4c', '#006666', '#008080', '#009999', '#00b3b3'],        // Dark to bright teal
  };

  // Helper function to get color based on metric and value
  const getValueColor = (metric, value) => {
    const colors = metricValueColors[metric];
    const numericValue = Number(value);
    if (colors && numericValue >= 1 && numericValue <= 5) {
      return colors[numericValue - 1];
    }
    return '#333333'; // Default color for undefined or out-of-range values
  };

  if (!data || data.length === 0) return <div>No Data</div>;

  return (
    <TableContainer
      ref={tableContainerRef} // Attach the ref here

      component={Paper}
      sx={{
        //   maxHeight: 600,
        position: 'relative',
        backgroundColor: '#000000', // Set container background to black
      }}
    >
      <Table
        stickyHeader
        aria-label="metrics table"
        size="small" // Use small size for compactness
        sx={{
          backgroundColor: '#000000',
          tableLayout: 'fixed', // Ensure fixed table layout for consistent column widths
        }}
      >
        <TableHead>
          <TableRow>
            {/* Fixed Left Column Header */}
            <TableCell
              sx={{
                position: 'sticky',
                left: 0,
                backgroundColor: '#000000', // Black background for sticky header
                zIndex: 4, // Higher z-index to stay above other cells
                width: 150 * scaleFactor, // Fixed width for "Metric" column
                minWidth: 150 * scaleFactor,
                maxWidth: 150 * scaleFactor,
                color: 'white', // White text
                //    borderRight: '1px solid #444', // Subtle border for separation
                borderBottom: '1px solid #444',
                //  borderBottom: 'none',
                padding: '4px 8px', // Reduced padding
                fontSize: '0.875rem', // Smaller font size
                fontWeight: 'bold', // Make headers bold
                textAlign: 'center', // Center align header text
                whiteSpace: 'nowrap', // Prevent text wrapping
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Metric
            </TableCell>
            {/* Scrollable Headers */}
            {data.map((month) => (
              <TableCell
                key={`${month.year}-${month.month}`}
                sx={{
                  width: 130 * scaleFactor, // Fixed width for "Metric" column
                  minWidth: 130 * scaleFactor,
                  maxWidth: 130 * scaleFactor,
                  backgroundColor: '#1a1a1a', // Dark gray background for headers
                  color: 'white', // White text
                  whiteSpace: 'nowrap',
                  //      borderRight: '1px solid #444', // Subtle border for separation
                  //    borderBottom: 'none',
                  borderBottom: '1px solid #444',

                  padding: '4px 8px', // Reduced padding
                  fontSize: '0.75rem', // Smaller font size
                  fontWeight: 'bold', // Make headers bold
                  textAlign: 'center', // Center align header text
                }}
              >
                {formatMonth(month.date)}
              </TableCell>
            ))}
            
          </TableRow>
        </TableHead>
        <TableBody>
          {metrics.map((metric) => (
            <TableRow
              key={metric}
              sx={{
                '&:hover': {
                  backgroundColor: '#2a2a2a', // Slightly lighter on hover
                },
                //     height: 32*scaleFactor, // Fixed row height for consistency
                backgroundColor: metricColors[metric] || '#121212', // Apply specific background color
              }}
            >
              {/* Fixed Left Column Cells */}
              <TableCell
                sx={{
                  position: 'sticky',
                  left: 0,
                  backgroundColor: metricColors[metric] || '#121212', // Apply specific background color
                  zIndex: 3, // Lower than header
                  width: 200 * scaleFactor, // Fixed width for "Metric" column
                  minWidth: 200 * scaleFactor,
                  maxWidth: 200 * scaleFactor,
                  color: 'white', // White text
                  //   borderRight: '1px solid #444', // Subtle border for separation
                  //   borderBottom: 'none',
                  borderBottom: '1px solid #444',

                  padding: '4px 8px', // Reduced padding
                  fontSize: '0.875rem', // Smaller font size
                  textAlign: 'center', // Center align metric names
                  whiteSpace: 'nowrap', // Prevent text wrapping
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {metric}
              </TableCell>
              {/* Scrollable Cells */}
              {data.map((month) => {
                let value = '';
                switch (metric) {
                  case 'SOTD':
                    value = month.sotd;
                    break;
                  case 'Repair Cost':
                    value = month.repairCost;
                    break;
                  case 'Service Level':
                    value = month.serviceLevel;
                    break;
                  case 'BER':
                    value = month.berScrap;
                    break;
                  case 'Warranty':
                    value = month.warranty;
                    break;
                  case 'Overall':
                    value = month.overallScore;
                    break;
                  default:
                    value = '';
                }
                return (
                  <TableCell
                    key={`${metric}-${month.date}`}
                    sx={{
                      color: 'white', // White text
                      backgroundColor: getValueColor(metric, value), // Apply value-based background color
                      //        borderRight: '1px solid #444', // Subtle border for separation
                      //      borderBottom: 'none',
                      borderBottom: '1px solid #444',

                      padding: '4px 8px', // Reduced padding
                      //fontSize: '1rem', // Smaller font size
                      //   height: 32*scaleFactor, // Fixed cell height
                      width: 200 * scaleFactor,
                      textAlign: 'center', // Center align cell values
                      whiteSpace: 'nowrap', // Prevent text wrapping
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '1.2rem', // Smaller font size
                        fontWeight: '700',
                        color: 'white', // Main text color
                        WebkitTextStroke: '1px black', // Outlines the text with black
                        textFillColor: 'white', // Ensures the fill is white
                      }}
                    >
                      {value}
                    </Typography>
                  </TableCell>
                );
              })}
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScrollableMetricsTable;
