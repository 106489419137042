import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Card, CardContent, CardActions, Paper } from '@mui/material';
import useAuthenticatedPost from '../../hooks/useAuthenticatedPost';
import config from '../../config';


const PartsEvalNew = () => {
    const [file, setFile] = useState(null);
    const [notes, setNotes] = useState('');
    const { post, isLoading, error } = useAuthenticatedPost(); // Use the post function from the custom hook


    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleNotesChange = (event) => {
        setNotes(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!file) {
            alert("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('notes', notes);

        try {
            const response = await post(`${config.apiBaseUrl}/partlist/upload`, formData);
            console.log('Upload successful:', response);
            alert("File uploaded successfully!");
        } catch (err) {
            console.error('Upload failed:', err);
            alert("Failed to upload file.");
        }
    };

    return (
        <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
            <Typography sx={{
                fontFamily: 'Orbitron, sans-serif',

                color: '#FDB813',
                fontStyle: 'italic',
                fontSize: '1.5rem',
            }}  >
                Part Evaluation - File Upload
            </Typography>
            <Card>
                <form onSubmit={handleSubmit}>
                    <CardContent>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            type="file"
                            onChange={handleFileChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText="Choose the file you want to upload"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Notes"
                            multiline
                            rows={4}
                            value={notes}
                            onChange={handleNotesChange}
                            helperText="Enter any notes about these parts"
                        />
                    </CardContent>
                    <CardActions>
                        <Button type="submit" variant="contained" color="primary">
                            Upload
                        </Button>
                    </CardActions>
                </form>
            </Card>
        </Paper>
    );
};

export default PartsEvalNew;
