// src/hooks/useAuthenticatedPut.js

import { useState, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { getAuthScopes } from '../config/scopes'; // Adjust the path based on your project structure
import { InteractionRequiredAuthError } from '@azure/msal-browser';

/**
 * Custom hook to handle authenticated PUT requests.
 * Utilizes dynamic scopes based on environment configurations.
 *
 * @returns {Object} - An object containing the put function, loading state, error, and a resetError function.
 */
const useAuthenticatedPut = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { instance, accounts } = useMsal();

  /**
   * Performs an authenticated PUT request to the specified URL with the provided data.
   *
   * @param {string} url - The API endpoint to send the PUT request to.
   * @param {Object|FormData|string} data - The data to be sent in the PUT request.
   * @param {string} [contentType='application/json'] - The Content-Type header for the request.
   * @returns {Promise<Object>} - The response data from the API.
   * @throws Will throw an error if no account is available or if the PUT request fails.
   */
  const put = useCallback(
    async (url, data, contentType = 'application/json') => {
      if (!accounts.length) {
        const noAccountError = new Error('No authenticated account found');
        setError(noAccountError);
        throw noAccountError;
      }

      setIsLoading(true);
      setError(null);

      try {
        // Retrieve scopes dynamically from environment variables
        const scopes = getAuthScopes();

        if (scopes.length === 0) {
          throw new Error('No authentication scopes defined in REACT_APP_AUTH_SCOPES.');
        }

        // Acquire token silently using dynamic scopes
        const accessTokenResponse = await instance.acquireTokenSilent({
          scopes: scopes, // Use dynamic scopes
          account: accounts[0],
        });

        let requestData = data;
        let headers = {
          Authorization: `Bearer ${accessTokenResponse.accessToken}`,
          'Content-Type': contentType,
        };

        // If it's JSON data and not already a string, stringify it
        if (contentType === 'application/json' && typeof data !== 'string') {
          requestData = JSON.stringify(data);
        }

        // If it's form data, let axios handle the Content-Type
        if (data instanceof FormData) {
          delete headers['Content-Type'];
        }

        // Make the authenticated PUT request
        const response = await axios.put(url, requestData, { headers });

        return response.data;
      } catch (err) {
        console.error('Error putting data:', err);
        setError(err);

        if (err instanceof InteractionRequiredAuthError) {
          // Retrieve scopes dynamically before initiating interactive authentication
          const scopes = getAuthScopes();

          if (scopes.length === 0) {
            console.error('No authentication scopes defined in REACT_APP_AUTH_SCOPES.');
            return;
          }

          // Initiate interactive token acquisition
          instance.acquireTokenRedirect({
            scopes: scopes, // Use dynamic scopes
          });
        }

        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [instance, accounts]
  );

  /**
   * Resets the error state.
   */
  const resetError = useCallback(() => {
    setError(null);
  }, []);

  return { put, isLoading, error, resetError };
};

export default useAuthenticatedPut;





/*
import { useState, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';

const useAuthenticatedPut = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { instance, accounts } = useMsal();

    const put = useCallback(async (url, data, contentType = 'application/json') => {
        if (!accounts.length) {
            const noAccountError = new Error("No authenticated account found");
            setError(noAccountError);
            throw noAccountError;
        }

        setIsLoading(true);
        setError(null);

        try {
            await instance.initialize();
            const accessToken = await instance.acquireTokenSilent({
                scopes: ['api://82ba1170-03da-46d4-be4b-dcc3fb4473f1/access_as_user'],
                account: accounts[0],
            });

            let requestData = data;
            let headers = { 
                Authorization: `Bearer ${accessToken.accessToken}`,
                'Content-Type': contentType
            };

            // If it's JSON data and not already a string, stringify it
            if (contentType === 'application/json' && typeof data !== 'string') {
                requestData = JSON.stringify(data);
            }

            // If it's form data, let axios handle the Content-Type
            if (data instanceof FormData) {
                delete headers['Content-Type'];
            }

            const response = await axios.put(url, requestData, { headers });

            return response.data;
        } catch (error) {
            console.error('Error putting data:', error);
            setError(error);
            if (error.name === "InteractionRequiredAuthError") {
                instance.acquireTokenRedirect({
                    scopes: ['api://82ba1170-03da-46d4-be4b-dcc3fb4473f1/access_as_user'],
                });
            }
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, [instance, accounts]);

    const resetError = useCallback(() => {
        setError(null);
    }, []);

    return { put, isLoading, error, resetError };
};

export default useAuthenticatedPut;
*/