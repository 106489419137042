import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Typography, Box, Button, Paper } from '@mui/material';
import useAuthenticatedData from '../../hooks/useAuthenticatedData';
import config from '../../config';


const columns = [
    { field: 'partnumber', headerName: 'Partnumber', width: 90 },
];


const PartsEvalDetail = () => {
    const { id } = useParams();
    const [details, setDetails] = useState(null);

    const { data, isLoading, error } = useAuthenticatedData(`${config.apiBaseUrl}/PartList/detail/${id}`);
    const { data: rows, isLoading: isLoadingRows, error: errorRows } = useAuthenticatedData(`${config.apiBaseUrl}/partlist/rows/${id}`);


    if (!data || !rows) {
        return (
            <div>Loading...</div>
        );
    }
    else {
        return (
            <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
                <p>Filename: {data.filename}</p>
                <p>Description: {data.description}</p>    

               

                <Typography variant="h4" component="h1" gutterBottom>
                    Detail for {data.filename}
                </Typography>

  
                <Box sx={{ height: 400, width: '100%', mt: 2 }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                       
                    />
                </Box>
          </Paper>
        );
    }
}

export default PartsEvalDetail;
