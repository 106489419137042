import React from 'react';
import { Paper, Box, Typography, Button, Grid, Divider } from '@mui/material';
import { Link } from 'react-router-dom'; // If using React Router for navigation

const ReportBug = () => {
    return (
        <Paper elevation={3} sx={{ p: 3, maxWidth: '800px', margin: 'auto', mt: 4 }}>
            <Typography variant="h2" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                Report a Bug
            </Typography>

            <Divider sx={{ my: 2 }} />

            <Box sx={{ mt: 2 }}>
                <Typography variant="body1" gutterBottom>
                    Have you encountered an issue or bug while using the platform? We appreciate your feedback and want to ensure a smooth experience for everyone.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    To report a bug, please log into our bug tracking system using the link below:
                </Typography>
                <Typography
                    variant="h6"
                    component="a"
                    href="http://projects2.its.local/projects/nexus/work_packages"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: '#1976d2', textDecoration: 'none', fontWeight: 'bold' }}
                >
                    Open Project Website
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
                    Use your work email and password to log in to log an issue. The project is open to everyone in the company, so you can see all reported issues and ongoing tasks even without logging in.
                </Typography>
       
       

                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            href="http://projects2.its.local/projects/nexus/work_packages"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Report a Bug
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            component={Link}
                            to="/help"
                        >
                            Need Help?
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <Divider sx={{ my: 4 }} />

            <Box sx={{ mt: 2 }}>
                <Typography variant="h5" gutterBottom>
                    Why Report Bugs?
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Your bug reports help us improve the platform deliver a better experience for everyone at ITS. Thank you for your contributions!
                </Typography>
            </Box>
        </Paper>
    );
};

export default ReportBug;