// src/components/VendorScatterChart.js
import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_VENDOR_SOTD_COSTS } from '../../../queries';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { CircularProgress, Typography, Paper, Box } from '@mui/material';

// Define the RepairVendorScatterChart component
const RepairVendorScatterChart = ({ acctNo }) => {
  const { loading, error, data } = useQuery(GET_VENDOR_SOTD_COSTS, {
    variables: { acctNo },
  });

  if (loading) return <CircularProgress />;
  if (error)
    return (
      <Typography color="error">
        Error loading data: {error.message}
      </Typography>
    );

  const { selectedVendor, otherVendors } = data.vendorSOTDCosts;

  // Prepare data for Recharts
  const selectedData = selectedVendor.map((vendor) => ({
    x: vendor.cost,
    y: vendor.repairTime,
    name: vendor.name,
  }));

  const otherData = otherVendors.map((vendor) => ({
    x: vendor.cost,
    y: vendor.repairTime,
    name: vendor.name,
  }));

  // Custom Tooltip Component
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const dataPoint = payload[0].payload;
      return (
        <Paper elevation={3} style={{ padding: '10px' }}>
          <Typography variant="subtitle1" color="textPrimary">
            {dataPoint.name}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" color="textSecondary">
              Cost:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              ${dataPoint.x.toLocaleString()}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" color="textSecondary">
              Repair Time:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {dataPoint.y} days
            </Typography>
          </Box>
        </Paper>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={500}>
      <ScatterChart
        margin={{ top: 20, right: 30, bottom: 20, left: 20 }}
      >
        <CartesianGrid />
        <XAxis
          type="number"
          dataKey="x"
          name="Cost"
          unit="$"
          label={{ value: 'Cost ($)', position: 'insideBottomRight', offset: -10 }}
          tickFormatter={(value) => `$${value / 1000}k`}
        />
        <YAxis
          type="number"
          dataKey="y"
          name="Repair Time"
          unit="days"
          label={{ value: 'Repair Time (days)', angle: -90, position: 'insideLeft' }}
        />
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ strokeDasharray: '3 3' }}
        />
        <Legend verticalAlign="top" />
        <Scatter
          name="Selected Vendor"
          data={selectedData}
          fill="#FF5733" // Distinct color for selected vendor
          shape="circle"
        />
        <Scatter
          name="Other Vendors"
          data={otherData}
          fill="#8884d8" // Different color for other vendors
          shape="circle"
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default RepairVendorScatterChart;
