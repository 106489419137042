// CustomerLandingPage.jsx

//import React from 'react';
import React, { useEffect } from 'react';
import { Box, Grid, Typography, Paper, Button } from '@mui/material';
import './Proficiency.css'; // Import the external CSS file
import { ArrowBack } from '@mui/icons-material';
import { ArrowForward } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { useQuery } from '@apollo/client';
import { GET_CUSTOMER_QUOTE_TO_CLOSE } from '../../queries';
import { GET_CUSTOMER_PROFICIENCY } from '../../queries';


const formatCurrency = (amount) => {
  if (amount === null || amount === undefined) return 'N/A';
  if (amount === 0) return '$0';
  const roundedAmount = amount < 0
    ? Math.ceil(amount)  // Round up for negative amounts to avoid cutting off cents improperly
    : Math.floor(amount); // Round down for positive amounts
  const absAmount = Math.abs(roundedAmount).toLocaleString();
  return roundedAmount < 0 ? `-$${absAmount}` : `$${absAmount}`;
};


function formatAsPercentage(value) {
  if (value == null) return ''; // Handle null/undefined gracefully
  return `${value.toFixed(1)}%`;
}

const Proficiency = ({ selectedCustomer }) => {

  const { data: quoteToCloseData, loading: quoteLoading, error: quoteError } = useQuery(GET_CUSTOMER_QUOTE_TO_CLOSE, {
    variables: { acctno: selectedCustomer?.acctNo || '' },
  });

  const { data: proficiencyData, loading: proficiencyLoading, error: proficiencyError } = useQuery(GET_CUSTOMER_PROFICIENCY, {
    variables: { acctno: selectedCustomer?.acctNo || '' },
  });

  useEffect(() => {
    if (quoteError) {
      console.error('Error fetching quote-to-close data:', quoteError);
    }
    if (proficiencyError) {
      console.error('Error fetching proficiency data:', proficiencyError);
    }
  }, [quoteError, proficiencyError]);

  if (quoteLoading || proficiencyLoading) return <Typography sx={{ p: 3 }}>Loading...</Typography>;
  if (quoteError || proficiencyError) {
    return <Typography sx={{ p: 3 }}>
      Error: {quoteError?.message || proficiencyError?.message}
    </Typography>;
  }
  if (!quoteToCloseData || !proficiencyData) return <Typography sx={{ p: 3 }}>No data available</Typography>;

  const quoteToClose = quoteToCloseData.quoteToCloseForCustomer;
  const proficiency = proficiencyData.customerProficiency;

  const InfoItem = ({ label, value, color }) => (
    <Box className="info-item">
      {color ? (
        <>
          <Typography sx={{ color }} className="info-label">{label}:</Typography>
          <Typography sx={{ color }} className="info-value">{value}</Typography>
        </>
      ) : (
        <>
          <Typography className="info-label">{label}:</Typography>
          <Typography className="info-value">{value}</Typography>
        </>
      )}
    </Box>
  );


  const Section = ({ title, children, helpText }) => (
    <Paper className="section">
      <Typography className="section-title">{title}</Typography>
      <Box className="section-content">
        {children}
      </Box>

      <Typography className="helptext">{helpText}</Typography>

    </Paper>
  );


  //const CustomerProficiency = ({ setActiveTab }) => {
  return (
    <Box className="proficiency">
      <Grid container spacing={2} className="grid-container">
        {/* Production Section */}
        <Grid item xs={12} sm={6} className="grid-item">
          <Section title="Sales vs. Sell Price" helpText="12 month average" >
            <Grid container spacing={2} className="nested-grid-container">
              <Grid item xs={12} sm={6} className="nested-grid-item">
                <InfoItem
                  label="Percentage"
                  value={formatAsPercentage(proficiency.salesVsSellPricePc)}
                  color={proficiency.salesVsSellPriceTotalDollarDifference < 0 ? "#A90000" : "#00a900"}
                />
                <InfoItem
                  label="Total Dollar Difference"
                  value={formatCurrency(proficiency.salesVsSellPriceTotalDollarDifference)}
                  color={proficiency.salesVsSellPriceTotalDollarDifference < 0 ? "#A90000" : "#00a900"}
                />
                <InfoItem label="total # of sales (lines)" value={proficiency.salesVsSellPriceTotalNoOfSales} />
                <InfoItem label="# of graded sales (lines)" value={proficiency.salesVsSellPriceNoOfGradedSales} />

                <InfoItem
                  label="Avg $ per sale"
                  value={formatCurrency(proficiency.salesVsSellPriceAvgDollarsPerSale)}
                  color={proficiency.salesVsSellPriceTotalDollarDifference < 0 ? "#A90000" : "#00a900"}
                />

              </Grid>
              <Grid item xs={12} sm={6} className="nested-grid-item">
                {/* Additional InfoItems can be added here */}
              </Grid>
            </Grid>
          </Section>
        </Grid>

        {/* Status Section */}
        <Grid item xs={12} sm={6} className="grid-item">
          <Section title="Quote to Close" helpText="12 month average"  >
            <Grid container spacing={2} className="nested-grid-container">
              <Grid item xs={12} sm={6} className="nested-grid-item">
                <InfoItem
                  label="Qty Percentage"
                  value={`${formatAsPercentage(quoteToClose.percentQ2CQty12m)} (${quoteToClose.qtySold12m} / ${quoteToClose.qtyQuoted12m})`}
                />
                <InfoItem
                  label="Dollars Percentage"
                  value={`${formatAsPercentage(quoteToClose.percentQ2CDollars12m)} (${formatCurrency(quoteToClose.dollarsSold12m)} / ${formatCurrency(quoteToClose.dollarsQuoted12m)})`}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="nested-grid-item">

              </Grid>
            </Grid>
          </Section>
        </Grid>


      </Grid>
    </Box>
  );
  // }
};

export default Proficiency;
