import React, { useState, useMemo } from 'react';
 
 
import useAuthenticatedData from '../../hooks/useAuthenticatedData';
import { enterpriseGridOverrideTheme } from '../../themes/EnterpriseGridTheme';
import config from '../../config';
import {
    DataGrid,
    GridToolbar,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarQuickFilter,
    GridToolbarExport,
} from '@mui/x-data-grid'; import { Box, Typography, CircularProgress, Paper,   } from '@mui/material';

import { useNavigate } from 'react-router-dom';





const AppFeatures = () => {

    const navigate = useNavigate();

    const handleRowClick = (params) => {
        navigate(`/app-features/${params.id}`);
    };


    const columns = useMemo(
        () => [
         //   { field: 'id', headerName: 'ID', width: 100 },
            { field: 'name', headerName: 'Name', width: 200 },
            { field: 'description', headerName: 'Description', width: 300 },
            { field: 'active', headerName: 'Active', width: 100, type: 'boolean' },
          
            { field: 'state', headerName: 'Availability', width: 150 },
            { field: 'status', headerName: 'Status', width: 150 },
            {
                field: 'userCount',
                headerName: 'User Count',
                width: 120,
                type: 'number',
                valueFormatter: (params) => {
                    return params?.value != null ? params.value : 'N/A';
                }
            }
        ],
        []
    );
    




    const { data: featureData, isLoading, error } = useAuthenticatedData(`${config.apiBaseUrl}/admin/app-features`);

    const rows = useMemo(() => {
        if (!featureData) return [];
        return featureData.map((item, index) => ({
            id: index,
            ...item,
        }));
    }, [featureData]);



    return (


<Paper elevation={3} sx={{ p: 3, height: '800px', maxWidth: '100%', mt: 4, overflow: 'hidden' }}>
<Typography variant="h1" component="h1" gutterBottom align="left">
                Application Features
            </Typography>
            <Box sx={{ height: '700px', overflowY: 'auto' }}>
            <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    density='compact'
                    rowsPerPageOptions={[5, 10, 25]}
                    disableSelectionOnClick={false}
                    onRowClick={(params) => handleRowClick(params.row)}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 5, page: 0 },
                        },
                        selection: {
                            selectedRowIds: rows.length > 0 ? [0] : []
                        }
                    }}
                    autoHeight={false}
                    headerHeight={40}
                    sx={{
                        ...enterpriseGridOverrideTheme,

                        height: '100%', // Use percentage height for responsive behavior
                        maxHeight: '700px', // Prevent exceeding a specific max height
                        '& .MuiDataGrid-cell': {
                            paddingLeft: '1', 
                            backgroundColor: 'white',
                            color: 'black',
                            
                          },
                        '& .MuiDataGrid-row': {
                            
                            cursor: 'pointer',
                            '&.Mui-selected': {
                                backgroundColor: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                            },
                        },
                    }}

                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                            //csvOptions: { fileName: generateExportFileName() },
                            //printOptions: { fileName: generateExportFileName() }
                        }
                    }}
                />
            </Box>
        </Paper>



    )
};

export default AppFeatures;