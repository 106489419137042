import React from 'react';
import { Paper, Box, Typography, Button } from '@mui/material';

const Usage = () => {
    return (
        <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
            <Typography variant="h1" component="h1" gutterBottom align="left">
                Usage
            </Typography>
            <Box sx={{ mt: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    href="https://app.powerbi.com/links/Cts6B_Ab1w?ctid=33b1ca7f-20ff-4f96-8e27-74acdd92e4bf&pbi_source=linkShare"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    View Nexus Usage in PowerBI
                </Button>
            </Box>
        </Paper>
    );
};

export default Usage;
