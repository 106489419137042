import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Typography, Box, Button, Paper } from '@mui/material';
import useAuthenticatedData from '../../hooks/useAuthenticatedData';
import { useNavigate } from 'react-router-dom';
import config from '../../config';


const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'filename', headerName: 'File Name', width: 200 },
    { field: 'currentVersion', headerName: 'Current Version', type: 'number', width: 130 },
    { field: 'description', headerName: 'Description', width: 200 },
    { field: 'uploadedBy', headerName: 'Uploaded By', width: 130 },
    { field: 'uploadedOn', headerName: 'Uploaded On', width: 150 },
    { field: 'lastEvaluated', headerName: 'Last Evaluated', width: 150 },
    { field: 'partCount', headerName: 'Part Count', type: 'number', width: 120 },
    { field: 'totalQty', headerName: 'Total Quantity', type: 'number', width: 130 },
];

const PartsEvalHome = () => {
    const navigate = useNavigate();

    const { data, isLoading, error } = useAuthenticatedData(`${config.apiBaseUrl}/PartList/partlists`);


    const handleRowClick = (params) => {
        navigate(`/parts-evaluation/detail/${params.id}`);
    };


    if (!data) {
        return <div>Loading...</div>
    }
    else {
        return (


            <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
               {/* Header Section */}
               <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Orbitron, sans-serif',
                            color: '#FDB813',
                            fontStyle: 'italic',
                            fontSize: '1.5rem',
                        }}
                    >
                        Part Evaluation
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate('/parts-evaluation/new')}
                    >
                        Upload New
                    </Button>
                </Box>

                <Box sx={{ height: 400, width: '100%', mt: 2 }}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                        onRowClick={handleRowClick}
                    />
                </Box>
            </Paper>
        );
    }
}

export default PartsEvalHome;