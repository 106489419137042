import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import GroupBox from '../../common/GroupBox';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Group } from '@mui/icons-material';

const RepairVendorSOTDTab = ({ acctNo }) => {
    // Static data for repairs summary
    const repairsSummary = {
        repairs: 30,
        onTime: "25 (83%)",
        meanDelta: "+4 days",
        sd: "12 (grade 5)"
    };

    // Static data for SOTD grades
    const sotdGrades = [
        { sd: -3.0, grade: 1 },
        { sd: -2.5, grade: 1 },
        { sd: -2.0, grade: 1 },
        { sd: -1.5, grade: 2 },
        { sd: -1.0, grade: 3 },
        { sd: -0.5, grade: 4 },
        { sd: 0, grade: 5 },
        { sd: 0.5, grade: 5 },
        { sd: 1.0, grade: 5 },
        { sd: 1.5, grade: 5 },
        { sd: 2.0, grade: 5 },
        { sd: 2.5, grade: 5 },
        { sd: 3.0, grade: 5 }
    ];

    // Sample data for line chart
    /*
    const chartData = [
        { date: '2023-10', value: 4 },
        { date: '2023-11', value: 4.2 },
        { date: '2023-12', value: 4.5 },
        { date: '2024-01', value: 4.3 },
        { date: '2024-02', value: 4.6 }
    ];
    */

    // DataGrid columns and rows
    const columns = [
        { field: 'dateReceived', headerName: 'Date Received', width: 130 },
        { field: 'docNumber', headerName: 'Doc #', width: 130 },
        { field: 'partNumber', headerName: 'Part Number', width: 130 },
        { field: 'repairTime', headerName: 'Total Repair Time', width: 150 },
        { field: 'outcome', headerName: 'Outcome', width: 130 }
    ];

    const rows = [
        { id: 1, dateReceived: '10/1/2024', docNumber: '#', partNumber: 'ABC', repairTime: '30 days', outcome: 'On Time' },
        { id: 2, dateReceived: '10/19/2024', docNumber: '#', partNumber: 'XYZ', repairTime: '45 days', outcome: 'Late by 2 days' },
        { id: 3, dateReceived: '10/23/2024', docNumber: '#', partNumber: 'ABC-123', repairTime: '15 days', outcome: 'On Time' },
        { id: 4, dateReceived: '10/23/2024', docNumber: '#', partNumber: 'ABC-123', repairTime: '55 days', outcome: 'Early by 1 day' }
    ];




    const generateChartData = () => {
        const data = [];
        const baseDate = new Date(2022, 11, 1); // Start from December 2022

        for (let i = 0; i < 24; i++) {
            const currentDate = new Date(baseDate);
            currentDate.setMonth(baseDate.getMonth() + i);

            // Generate somewhat realistic looking data with some correlation
            const baseValue = 3 + Math.sin(i / 4) + (Math.random() - 0.5);
            const standardDeviation = Math.max(0, Math.min(5, baseValue));
            const absoluteDays = Math.round(standardDeviation * 10 + Math.random() * 5);

            data.push({
                date: currentDate.toISOString().slice(0, 7), // YYYY-MM format
                standardDeviation: Number(standardDeviation.toFixed(2)),
                absoluteDays: absoluteDays
            });
        }
        return data;
    };


    const chartData = generateChartData();

    return (
        <Box sx={{ width: '100%', p: 0 }}>
            <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
                {/* Box A - Repairs Summary */}
                <GroupBox label="SOTD Grades">

                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell>Repairs</TableCell>
                                    <TableCell>{repairsSummary.repairs}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>On Time</TableCell>
                                    <TableCell>{repairsSummary.onTime}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Mean delta</TableCell>
                                    <TableCell>{repairsSummary.meanDelta}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>SD</TableCell>
                                    <TableCell>{repairsSummary.sd}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                </GroupBox>

                {/* Box B - SOTD Grades */}
                <GroupBox label="SOTD Grades" >

                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={13} align="center">
                                        How SOTD grades are applied
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>SD</TableCell>
                                    {sotdGrades.map((grade, index) => (
                                        <TableCell key={index}>{grade.sd}</TableCell>
                                    ))}
                                </TableRow>
                                <TableRow>
                                    <TableCell>Grade</TableCell>
                                    {sotdGrades.map((grade, index) => (
                                        <TableCell key={index}>{grade.grade}</TableCell>
                                    ))}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                </GroupBox>
            </Box>

            <Box sx={{ display: 'flex', width: '100%' }}>
                {/* Box C - Line Chart */}
                <Box sx={{ width: '50%' }}>
                    <GroupBox label="SOTD Over Time" >
                        <Box sx={{ height: '300px' }}>

                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={chartData}
                                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="date"
                                        tickFormatter={(date) => {
                                            const [year, month] = date.split('-');
                                            return `${month}/${year.slice(2)}`;
                                        }}
                                    />
                                    <YAxis
                                        yAxisId="left"
                                        domain={[0, 5]}
                                        label={{
                                            value: 'Standard Deviation',
                                            angle: -90,
                                            position: 'insideLeft'
                                        }}
                                    />
                                    <YAxis
                                        yAxisId="right"
                                        orientation="right"
                                        domain={[0, 'auto']}
                                        label={{
                                            value: 'Days',
                                            angle: 90,
                                            position: 'insideRight'
                                        }}
                                    />
                                    <Tooltip
                                        formatter={(value, name) => {
                                            if (name === "standardDeviation") {
                                                return [value.toFixed(2), "SD"];
                                            } else if (name === "absoluteDays") {
                                                return [value.toFixed(0), "Days"];
                                            }
                                            return [value, name];
                                        }}
                                        labelFormatter={(date) => {
                                            const [year, month] = date.split('-');
                                            return `Date: ${month}/${year.slice(2)}`;
                                        }}
                                        contentStyle={{
                                            backgroundColor: 'white',
                                            border: '1px solid #cccccc',
                                            color: 'black'
                                        }}
                                        labelStyle={{ color: 'black' }}
                                        itemStyle={{ color: 'black' }}
                                    />
                                    <Legend />
                                    <Line
                                        yAxisId="left"
                                        type="linear"
                                        dataKey="standardDeviation"
                                        stroke="#8884d8"
                                        name="Standard Deviation"
                                        dot={false}
                                    />
                                    <Line
                                        yAxisId="right"
                                        type="linear"
                                        dataKey="absoluteDays"
                                        stroke="#82ca9d"
                                        name="Absolute Days"
                                        dot={false}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </Box>
                    </GroupBox>
                </Box>

                {/* Box D - Data Grid */}
                <Box sx={{ height: '300px' }}>

                    <GroupBox label="Recent Repairs" >

                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            disableSelectionOnClick
                            density="compact"
                        />

                    </GroupBox>
                </Box>
            </Box>
        </Box>
    );
};

export default RepairVendorSOTDTab;